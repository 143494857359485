import { Component, OnDestroy, OnInit } from "@angular/core";
import { AdminformComponent } from "../../controllers/adminform/adminform.component";
import {
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from "@angular/forms";

import { environment } from "../../../../environments/environment";
import { BookarfilesService } from "../../services/bookarfiles.service";
import { Injector } from "@angular/core";
import {
  ArType,
} from "../../models/Book";

@Component({
  selector: "app-bookform",
  templateUrl: "./bookform.component.html",
  styleUrls: ["./bookform.component.scss"],
})
export class BookformComponent
  extends AdminformComponent
  implements OnInit, OnDestroy
{
  id: any;
  params: any;

  languagesItemList = [];
  languagesSettings = {};

  categoriesItemList = [];
  categoriesSettings = {};

  editorsItemList = [];
  editorsSettings = {};

  nameControl: FormControl;
  keyControl: FormControl;
  descriptionControl: FormControl;
  shortDescriptionControl: FormControl;
  linkControl: FormControl;
  courseLinkControl: FormControl;
  languageControl: FormControl;
  categoryControl: FormControl;
  editorControl: FormControl;
  marketplaceControl: FormControl;
  publishedControl: FormControl;
  arCameraContentControl: FormControl;
  maintenanceControl: FormControl;

  showMaintenanceBtn: boolean = false;

  shoppingId: string = "";
  parentId: string = "";
  parentName: string = "";
  parentIsDeleted: boolean = false;
  parentUrlType: string = "";
  bookAr: FormControl;
  bookArRemoved: FormControl;
  coverImage: FormControl;
  universalTargetImage: FormControl;
  contentPreview: FormControl;

  appImage: FormControl;
  appImageBgColor: FormControl;
  appImageBgColorBgImage: string = "";

  xmlFile: FormControl;
  dataFile: FormControl;
  fullBook: FormControl;

  // fullBookIsRequired:boolean = false;

  coverImageToolTipOptions = Object.assign({}, this.toolTipOptions);
  contentPreviewToolTipOptions = Object.assign({}, this.toolTipOptions);
  shortDescriptionToolTipOptions = Object.assign({}, this.toolTipOptions);
  linkToolTipOptions = Object.assign({}, this.toolTipOptions);
  courseLinkToolTipOptions = Object.assign({}, this.toolTipOptions);
  appImageToolTipOptions = Object.assign({}, this.toolTipOptions);
  maintenanceToolTipOptions = Object.assign({}, this.toolTipOptions);

  metaKeywordsTooltip = Object.assign({}, this.toolTipOptions);

  bookKeyPrefix;
  editable: boolean = true;
  currentEditor: string = "";
  keepConnectionTimeout;
  prevEditorId = null;

  constructor(
    private bookarfilesService: BookarfilesService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.tableName = "books";
    this.formName = "book";
    this.setPermissions(this.tableName);
    this.sts.st = { form: this.localeText };

    this.permissions = this.permissionsService.getPermissions(this.tableName);

    this.languagesSettings = {
      text: "Select Language",
      classes: "languages-list",
      enableSearchFilter: true,
      singleSelection: true,
      enableFilterSelectAll: false,
      disabled: !this.permissions.Edit,
    };

    this.categoriesSettings = {
      text: "Select Category",
      classes: "categories-list",
      enableSearchFilter: true,
      singleSelection: true,
      enableFilterSelectAll: false,
      disabled: !this.permissions.Edit,
    };

    this.editorsSettings = {
      text: "Select Editor",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      classes: "users-list",
      enableSearchFilter: true,
      disabled: !this.permissions.Edit,
    };

    // Update
    this.params = this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];

      var arFiles = [];

      if (this.id) {
        this.admingridService.getItem(this.tableName, this.id).subscribe(
          (data) => {
            // console.log('data', data);

            if (data.editable === false) {
              this.editable = false;
              this.currentEditor = data.currentEditor;
            } else {
              this.keepConnected();
            }

            //this.sts.st = data.st;
            this.sts.st = Object.assign({}, this.sts.st, data.st);

            this.replaceSystemTitle("books.book");
            this.replaceTexts();

            this.shoppingId = data["item"]["shopping_id"];
            this.parentId = data["item"]["parent_id"];
            this.parentName = data["item"]["parent_name"];
            this.parentIsDeleted = data["item"]["parent_is_deleted"];
            this.parentUrlType = data["item"]["parent_has_shopping_id"]
              ? "show"
              : "edit";

            this.mainForm.controls["name"].setValue(data["item"]["name"]);
            this.bookKeyPrefix = this.id + "-";
            this.mainForm.controls["key"].setValue(data["item"]["key"]);
            this.mainForm.controls["description"].setValue(
              data["item"]["description"]
            );
            this.mainForm.controls["shortDescription"].setValue(
              data["item"]["short_description"]
            );
            this.mainForm.controls["link"].setValue(data["item"]["link"]);
            this.mainForm.controls["courseLink"].setValue(data["item"]["course_link"]);
            this.mainForm.controls["language"].setValue(
              data["item"]["language"]
            );
            this.mainForm.controls["id"].setValue(data["item"]["id"]);
            this.mainForm.controls["marketplace"].setValue(
              data["item"]["marketplace"]
            );
            this.mainForm.controls["published"].setValue(
              data["item"]["published"]
            );
            this.mainForm.controls["arCameraContent"].setValue(
              data["item"]["ar_camera_content"]
            );
            this.mainForm.controls["maintenance"].setValue(
              data["item"]["maintenance"]
            );

            var isCopy = this.formType == "copy" ? true : false;
            if (
              !data["item"]["maintenance"] &&
              data["item"]["published"] &&
              !isCopy
            ) {
              this.readonly = true;
            }
            if (data["item"]["published"]) {
              this.showMaintenanceBtn = true;
            }

            var i = 0;
            var arFilesGrouped = data["item"]["ar_files_grouped"];
            //console.log("data: ", data["item"]);

            if (arFilesGrouped.length > 0) {
              this.mainForm.controls["bookAr"]["controls"] = [];
            }
            for (const key in arFilesGrouped) {
              if (!arFilesGrouped[key].hasOwnProperty("files")) continue;
              const control = <FormArray>this.mainForm.controls["bookAr"];
              //const fileType :ArType = this.getArType(arFilesGrouped[key]['files'][0]['ar_type']);
              const fileType: ArType = arFilesGrouped[key].type;

              if (fileType === "ar") {
                control.push(this.bookarfilesService.initBookAr());
                var allowedTypes = [
                  "arAndroid",
                  "arIos",
                  "arWebgl",
                  "arTargetImage",
                ];
              }
              if (fileType === "video") {
                control.push(this.bookarfilesService.initBookVideo());
                var allowedTypes = ["video", "videoTargetImage"];
              }
              if (fileType === "group") {
                control.push(this.bookarfilesService.initBookGroup());
                var allowedTypes = ["groupTargetImage"];
              }

              this.mainForm.controls["bookAr"]["controls"][i]["controls"][
                "type"
              ].setValue(fileType);
              this.mainForm.controls["bookAr"]["controls"][i]["controls"][
                "uid"
              ].setValue(arFilesGrouped[key].uid);
              if (fileType !== "group") {
                this.mainForm.controls["bookAr"]["controls"][i]["controls"][
                  "title"
                ].setValue(arFilesGrouped[key].title);
                this.mainForm.controls["bookAr"]["controls"][i]["controls"][
                  "keywords"
                ].setValue(arFilesGrouped[key].keywords);
              }

              if (arFilesGrouped.hasOwnProperty(key)) {
                const value = arFilesGrouped[key];
                value.files.forEach((element) => {
                  // console.log("mf",this.mainForm);
                  const type = element["form_control_name"].split("-");
                  if (allowedTypes.includes(type[1])) {
                    this.mainForm.controls["bookAr"]["controls"][i]["controls"][
                      element.ar_type
                    ].setValue(element);
                  }
                });
              }
              i++;
            }

            var groupContents = data["item"]["group_contents"];
            groupContents.forEach((content) => {
              const parentUid = content.parentUid;
              const fc = this.getGroupById(parentUid);
              if (fc) {
                const contents = fc["controls"].contents;
                const fileType = content.type;
                if (fileType === "gc_ar") {
                  const formGroup = this.bookarfilesService.initBookArGroup(content.uid);
                  this.setFormGroupValues(formGroup, content);
                  contents.value.push(formGroup);
                }
                if (fileType === "gc_video") {
                  const formGroup =
                    this.bookarfilesService.initBookVideoGroup(content.uid);
                  this.setFormGroupValues(formGroup, content);
                  contents.value.push(formGroup);
                }
              }
            });

            this.mainForm.controls["coverImage"].setValue(
              data["item"]["cover_image"]
            );
            this.mainForm.controls["universalTargetImage"].setValue(
              data["item"]["universal_target_image"]
            );
            this.mainForm.controls["contentPreview"].setValue(
              data["item"]["content_preview"]
            );
            this.mainForm.controls["appImage"].setValue(
              data["item"]["app_image"]
            );
            this.mainForm.controls["appImageBgColor"].setValue(
              data["item"]["app_image_bg_color"]
            );
            if (
              data["item"]["app_image"].length > 0 &&
              data["item"]["app_image"][0]["id"]
            ) {
              const url = `${environment.backendApiUrl}${environment.backendApiVersion}/files/getbyid/${data["item"]["app_image"][0]["id"]}`;
              this.setColorPickerBg(url);
            }

            this.mainForm.controls["xmlFile"].setValue(
              data["item"]["xml_file"]
            );
            this.mainForm.controls["dataFile"].setValue(
              data["item"]["data_file"]
            );
            this.mainForm.controls["fullBook"].setValue(
              data["item"]["full_book"]
            );

            this.createItemList(
              data["languages"],
              [data["item"]["language"]],
              "languages",
              "language"
            );
            this.createItemList(
              data["categories"],
              [data["item"]["category"]],
              "categories",
              "category"
            );
            this.createItemList(
              data["editors"],
              data["selectedEditors"],
              "editors",
              "editor"
            );

            this.formReadyToShow = true;
          },
          (error) => {
            console.log(<any>error);
            var text =
              typeof error["error"] == "object" &&
              error.error.hasOwnProperty("message")
                ? error.error.message
                : error.message;
            this.messageService.sendMessage({ text: text, type: "danger" });
          }
        );
      }

      this.nameControl = this.fb.control("", [
        Validators.required,
        Validators.minLength(3),
      ]);
      this.keyControl = this.fb.control("", [
        Validators.required,
        Validators.minLength(3),
      ]);

      this.descriptionControl = this.fb.control("");
      this.shortDescriptionControl = this.fb.control("");
      this.linkControl = this.fb.control("");
      this.courseLinkControl = this.fb.control("");
      this.languageControl = this.fb.control("", [Validators.required]);
      this.categoryControl = this.fb.control("", [Validators.required]);
      this.editorControl = this.fb.control("", [Validators.required]);
      this.marketplaceControl = this.fb.control("");
      this.publishedControl = this.fb.control("");
      this.arCameraContentControl = this.fb.control("");
      this.maintenanceControl = this.fb.control("");
      this.bookAr = this.fb.control(
        "" /* , [createFileUploadRequiredValidator(1)] */
      );
      this.bookArRemoved = this.fb.control(
        "" /* , [createFileUploadRequiredValidator(1)] */
      );
      this.coverImage = this.fb.control(
        "" /* , [createFileUploadRequiredValidator(1)] */
      );
      this.universalTargetImage = this.fb.control(
        "" /* , [createFileUploadRequiredValidator(1)] */
      );
      this.appImage = this.fb.control("", [Validators.required]);
      this.appImageBgColor = this.fb.control("");
      this.contentPreview = this.fb.control(
        "" /* , [createFileUploadRequiredValidator(1)] */
      );

      // this.fullBookIsRequired = true;

      this.xmlFile = this.fb.control("");
      this.dataFile = this.fb.control("");
      this.fullBook = this.fb.control(""); // TODO: Required-et dinamikusan váltogatni, amikor kezeli a feltöltő (this.fullBookIsRequired ? [Validators.required] : null)

      this.mainForm = this.fb.group({
        id: "",
        name: this.nameControl,
        key: this.keyControl,
        description: this.descriptionControl,
        shortDescription: this.shortDescriptionControl,
        link: this.linkControl,
        courseLink: this.courseLinkControl,
        language: this.languageControl,
        category: this.categoryControl,
        editor: this.editorControl,
        marketplace: this.marketplaceControl,
        published: this.publishedControl,
        arCameraContent: this.arCameraContentControl,
        maintenance: this.maintenanceControl,
        coverImage: this.coverImage,
        universalTargetImage: this.universalTargetImage,
        contentPreview: this.contentPreview,

        appImage: this.appImage,
        appImageBgColor: this.appImageBgColor,

        xmlFile: this.xmlFile,
        dataFile: this.dataFile,
        fullBook: this.fullBook,

        bookAr: this.fb.array([this.bookarfilesService.initBookAr()]),
        bookArRemoved: this.fb.array([]),
      });

      // console.log('this.mainForm:', this.mainForm.controls);
    });

    // Create
    if (this.formType == "create") {
      this.admingridService.createItem(this.tableName).subscribe(
        (data) => {
          // console.log('data:', data);

          //this.sts.st = data.st;
          this.sts.st = Object.assign({}, this.sts.st, data.st);
          this.replaceSystemTitle("books.book");
          this.replaceTexts();
          this.createItemList(data.languages, false, "languages", "language");
          this.createItemList(data.categories, false, "categories", "category");

          var currentEditor = data.editors.filter(
            (editor) =>
              editor.id == JSON.parse(sessionStorage.getItem("userdata")).id
          );
          this.createItemList(data.editors, currentEditor, "editors", "editor");
          this.formReadyToShow = true;

          this.bookKeyPrefix = data.nextid;
        },
        (error) => {
          console.log(error);
          var text =
            typeof error["error"] == "object" &&
            error.error.hasOwnProperty("message")
              ? error.error.message
              : error.message;
          this.messageService.sendMessage({ text: text, type: "danger" });
        }
      );
    }
  }
  getGroupById(uid: number): FormControl {
    for (
      let i = 0;
      i < this.mainForm.controls["bookAr"]["controls"].length;
      i++
    ) {
      const element = this.mainForm.controls["bookAr"]["controls"][i];
      //console.log(element);
      if (element.value.uid == uid) {
        return element;
      }
    }
  }
  setFormGroupValues(formGroup: FormGroup, content: any) {
    formGroup["controls"]["title"].setValue(content["title"]);
    formGroup["controls"]["keywords"].setValue(content["keywords"]);
    content.files.forEach((element) => {
        formGroup["controls"][element.ar_type].setValue(element);
    });
  }

  createItemList(items, selectedItems, listname, controlname) {
    var itemListArray = [];
    var selectedLangName;
    var selectedCatName;

    if (controlname == "language") {
      for (const [key, value] of Object.entries(items)) {
        let code = key.split(".");
        itemListArray.push({ id: key, itemName: value, code: code[1] });
        if (controlname == "language" && selectedItems && key == selectedItems)
          selectedLangName = value;
      }
    } else if (controlname == "category") {
      for (const [key, value] of Object.entries(items)) {
        itemListArray.push({ id: key, itemName: value });
        if (controlname == "category" && selectedItems && key == selectedItems)
          selectedCatName = value;
      }
    } else {
      items.forEach((item) => {
        itemListArray.push({ id: item.id, itemName: item.name });
      });
    }

    this[listname + "ItemList"] = itemListArray;

    if (selectedItems) {
      const selectedItemListArray = [];
      if (controlname == "language") {
        for (const [key, value] of Object.entries(selectedItems)) {
          let code = selectedItems[0].split(".");
          selectedItemListArray.push({
            id: value,
            itemName: selectedLangName,
            code: code[1],
          });
        }
      } else if (controlname == "category") {
        for (const [key, value] of Object.entries(selectedItems)) {
          selectedItemListArray.push({ id: value, itemName: selectedCatName });
        }
      } else {
        selectedItems.forEach((item) => {
          selectedItemListArray.push({ id: item.id, itemName: item.name });
        });
      }

      this.mainForm.controls[controlname].setValue(selectedItemListArray);
    }
  }

  replaceTexts() {
    this.sts.replace("form.select_item", [
      { find: ":item", replace: "books.language" },
    ]);
    this.languagesSettings["text"] = this.sts.c("form.select_item");
    this.languagesSettings["searchPlaceholderText"] = this.sts.c("form.search");

    this.editorsSettings["selectAllText"] = this.sts.c("form.select_all");
    this.editorsSettings["unSelectAllText"] = this.sts.c("form.un_select_all");
    this.editorsSettings["searchPlaceholderText"] = this.sts.c("form.search");
    this.editorsSettings["filterSelectAllText"] = this.sts.c(
      "form.select_all_filtered_results"
    );
    this.editorsSettings["filterUnSelectAllText"] = this.sts.c(
      "form.un_select_all_filtered_results"
    );

    var booksLanguage = this.sts.c("books.language");
    this.sts.replace("form.select_item", [
      { find: booksLanguage, replace: "books.category" },
    ]);
    this.categoriesSettings["text"] = this.sts.c("form.select_item");
    this.categoriesSettings["searchPlaceholderText"] =
      this.sts.c("form.search");

    var booksCategory = this.sts.c("books.category");
    this.sts.replace("form.select_item", [
      { find: booksCategory, replace: "books.editors" },
    ]);
    this.editorsSettings["text"] = this.sts.c("form.select_item");

    this.coverImageToolTipOptions.text = this.sts.c("books.ttip_cover_image");
    this.metaKeywordsTooltip.text = this.sts.c(
      "books.ttip_ar_meta_keywords_tooltip"
    );
    this.shortDescriptionToolTipOptions.text = this.sts.c(
      "books.ttip_short_description"
    );
    this.linkToolTipOptions.text = this.sts.c("books.ttip_link");
    this.courseLinkToolTipOptions.text = this.sts.c("books.ttip_courselink");
    this.appImageToolTipOptions.text = this.sts.c("books.ttip_app_image");
    this.maintenanceToolTipOptions.text = this.sts.c("books.ttip_maintenance");
  }

  setColorPickerBg(value) {
    //console.log("setColorPickerBg",value);
    this.appImageBgColorBgImage = value;
  }

  removeColorPickerBg() {
    this.appImageBgColorBgImage = "";
  }

  refreshPage() {
    window.location.reload();
  }

  keepConnected() {
    this.admingridService.keepBookEditConnection(this.id).subscribe((data) => {
      let alreadyModified = false;
      this.currentEditor = data["currentEditor"];
      if (!this.prevEditorId) {
        this.prevEditorId = data["currentEditorId"];
      } else {
        if (this.prevEditorId !== data["prevEditorId"]) {
          alreadyModified = true;
          this.currentEditor = data["prevEditor"];
        }
      }
      if (data["editable"] === false || alreadyModified) {
        document.getElementById("opendeleteModal").click();
      }
    });
    this.keepConnectionTimeout = setTimeout(() => {
      this.keepConnected();
    }, 30000);
  }

  ngOnDestroy() {
    clearTimeout(this.keepConnectionTimeout);
    this.params.unsubscribe();
    this.prevEditorId = null;
  }
}
