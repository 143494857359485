///<reference path="app-routing.module.ts"/>
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DesigntestComponent} from './pages/designtest/designtest.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {routes} from './app.routes';
import {HomeComponent} from './pages/home/home.component';
import {AdminComponent} from './admin/admin.component';
import {DashboardComponent} from './admin/pages/dashboard/dashboard.component';
import {NavbartopComponent} from './admin/layout/navbar/navbartop.component';
import {NavbarleftComponent} from './admin/layout/navbar/navbarleft.component';
import {NavbaritemComponent} from './admin/layout/navbar/navbaritem.component';
import {UsersComponent} from './admin/pages/users/users.component';
import {BooksComponent} from './admin/pages/books/books.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { MyHttpInterceptor } from './my-http-interceptor';
import {OrdersComponent} from './admin/pages/orders/orders.component';
import {ObjectsComponent} from './admin/pages/objects/objects.component';
import {ProductsComponent} from './admin/pages/products/products.component';

import {AdmingridService} from './admin/services/admingrid.service';
import {NavbarService} from './admin/services/navbar.service';
import {TimelineService} from './admin/services/timeline.service';

import {AuthService} from './services/auth.service';

import {RegisterComponent} from './pages/register/register.component';
import {LoginComponent} from './pages/login/login.component';
import {AuthGuardService} from './services/auth-guard.service';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AgGridModule } from 'ag-grid-angular';



import {JwtModule} from '@auth0/angular-jwt';
import { LogoutComponent } from './services/logout.component';
import { RolesComponent } from './admin/pages/roles/roles.component';
import {TooltipModule} from '@cloudfactorydk/ng2-tooltip-directive';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { GridheaderComponent } from './admin/layout/grid/gridheader/gridheader.component';
import { GridactionbtnsComponent } from './admin/layout/grid/gridactionbtns/gridactionbtns.component';
import { AdmingridComponent } from './admin/controllers/admingrid/admingrid.component';
import { UserformComponent } from './admin/pages/users/userform.component';
import { AdminformComponent } from './admin/controllers/adminform/adminform.component';
import { FormheaderComponent } from './admin/layout/form/formheader/formheader.component';
import { FormbuttonsComponent } from './admin/layout/form/formbuttons/formbuttons.component';
import { ForminputComponent } from './admin/layout/form/forminput/forminput.component';
import { FormmultiselectComponent } from './admin/layout/form/formmultiselect/formmultiselect.component';
import { RoleformComponent } from './admin/pages/roles/roleform.component';

import {MainPipe} from './shared/pipes/pipes.module';
import { ModalComponent } from './admin/layout/modal/modal.component';
import { OrganizationsComponent } from './admin/pages/organizations/organizations.component';
import { OrganizationformComponent } from './admin/pages/organizations/organizationform.component';
import { FormtextareaComponent } from './admin/layout/form/formtextarea/formtextarea.component';
import { SystemeventsComponent } from './admin/pages/systemevents/systemevents.component';
import { DropdownFilter } from './admin/controllers/admingrid/dropdownfilter.component';

import { FormswitchComponent } from './admin/layout/form/formswitch/formswitch.component';

import {UiSwitchModule} from 'ngx-ui-switch';
// import {TooltipModule} from 'ng2-tooltip-directive';

import { environment } from 'src/environments/environment';

// import {NumberPickerModule} from 'ng-number-picker';
import {SubtitleTrackComponent} from './admin/pages/subtitle-track/subtitle-track.component';
import { FormnumberpickerComponent } from './admin/layout/form/formnumberpicker/formnumberpicker.component';

import { NgxMaskModule } from 'ngx-mask';
import { BookformComponent } from './admin/pages/books/bookform.component';
import { MenutopComponent } from './layout/menu/menutop.component';
import { WebComponent } from './web.component';
import { MarketplaceComponent } from './admin/pages/marketplace/marketplace.component';
import { FormfileuploadComponent } from './admin/layout/form/formfileupload/formfileupload.component';
import { FormsinglefileComponent } from './admin/layout/form/formfileupload/formsinglefile/formsinglefile.component';
import {FilterPipe} from './shared/pipes/pipes';
import {ArraySortPipe} from './shared/pipes/pipes';
import { ShoppingsComponent } from './admin/pages/shoppings/shoppings.component';
import { ShoppingformComponent } from './admin/pages/shoppings/shoppingform.component';

import {SafePipe} from './shared/pipes/pipes';
import { TooltipComponent } from './admin/layout/tooltip/tooltip.component';
import { FormfileuploadbookarComponent } from './admin/layout/form/formfileuploadbookar/formfileuploadbookar.component';

import { FormcolorpickerComponent } from './admin/layout/form/formcolorpicker/formcolorpicker.component';
import { ColorPickerModule } from 'ngx-color-picker';

import {LabelManagerComponent} from './admin/pages/label-manager/label-manager.component';
import { TimelineComponent } from './admin/pages/timeline/timeline.component';
import { FileselectComponent } from './admin/pages/fileselect/fileselect.component';
import { DraggableModule } from './admin/modules/draggable/draggable.module';
import { ArEditorComponent } from './admin/pages/ar-editor/ar-editor.component';
import { PreloaderComponent } from './admin/pages/preloader/preloader.component';
import { ArLabelComponent } from './admin/pages/ar-label/ar-label.component';
import { CanDeactivateGuard } from './admin/guards/can-deactivate.guard';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
//import {AdminComponent} from './admin/admin.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PrototipusComponent } from './pages/prototipus/prototipus.component';
import { GyartokornyezetComponent } from './pages/gyartokornyezet/gyartokornyezet.component';
import { TechnologiaComponent } from './pages/technologia/technologia.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SubpageheaderComponent } from './layout/subpageheader/subpageheader.component';
import { PrototyperowComponent } from './layout/prototyperow/prototyperow.component';
import { GridmodalComponent } from './admin/layout/grid/gridmodal/gridmodal.component';

import { LanguagecheckComponent } from './admin/pages/languagecheck/languagecheck.component';
import { DisplayobjectComponent } from './admin/modules/displayobject/displayobject.component';
import { KeyCodesCreationComponent } from './admin/pages/key-codes-creation/key-codes-creation.component';
import { KeyCodesCreationFormComponent } from './admin/pages/key-codes-creation/key-codes-creation-form.component';
import {downloadQrCodesZipComponent} from "./admin/pages/key-codes-creation/download-qr-codes-zip.component";
import {KeyCodesComponent} from "./admin/pages/key-codes/key-codes.component";
import {KeyCodesFormComponent} from "./admin/pages/key-codes/key-codes-form.component";
import { QrCodeComponent } from './admin/pages/qr-code/qr-code.component';
import { SystemmessageComponent } from './admin/pages/systemmessage/systemmessage.component';
import {ClientStatisticsComponent} from "./admin/pages/client-statistics/client-statistics.component";
import { NgxEchartsModule } from 'ngx-echarts';
import  * as echarts from 'echarts';
import { NgxEchartsConfig } from 'ngx-echarts/lib/ngx-echarts.directive';
//import { TooltipDirective } from './directives/tooltip.directive';
export function tokenGetter() {
    return sessionStorage.getItem('token');
}
export function ec(){
  const p = new Promise((resolve, reject) => {
      resolve(echarts);
  });
  return p;
}
const echartsConf :NgxEchartsConfig = { echarts:ec};

@NgModule({
    declarations: [
        AppComponent,
        DesigntestComponent,
        HomeComponent,
        AdminComponent,
        DashboardComponent,
        NavbartopComponent,
        NavbarleftComponent,
        NavbaritemComponent,
        UsersComponent,
        BooksComponent,
        OrdersComponent,
        ObjectsComponent,
        ProductsComponent,
        RegisterComponent,
        LoginComponent,
        LogoutComponent,
        RolesComponent,
        GridheaderComponent,
        GridactionbtnsComponent,
        AdmingridComponent,
        UserformComponent,
        AdminformComponent,
        FormheaderComponent,
        FormbuttonsComponent,
        ForminputComponent,
        FormmultiselectComponent,
        RoleformComponent,
        ModalComponent,
        OrganizationsComponent,
        OrganizationformComponent,
        FormtextareaComponent,
        SystemeventsComponent,
        ClientStatisticsComponent,
        FormswitchComponent,
        FormnumberpickerComponent,
        DropdownFilter,
        BookformComponent,
        MenutopComponent,
        WebComponent,
        MarketplaceComponent,
        FilterPipe,
        ArraySortPipe,
        ShoppingsComponent,
        ShoppingformComponent,
        FormfileuploadComponent,
        FormsinglefileComponent,
        SafePipe,
        TooltipComponent,
        LabelManagerComponent,
        TimelineComponent,
        FileselectComponent,
        ArEditorComponent,
        TooltipComponent,
        FormfileuploadbookarComponent,
        FormcolorpickerComponent,
        PreloaderComponent,
        ArLabelComponent,
        SubtitleTrackComponent,
        PagenotfoundComponent,
        PrototipusComponent,
        GyartokornyezetComponent,
        TechnologiaComponent,
        FooterComponent,
        SubpageheaderComponent,
        PrototyperowComponent,
        GridmodalComponent,
        LanguagecheckComponent,
        DisplayobjectComponent,
        KeyCodesCreationComponent,
        KeyCodesCreationFormComponent,
        KeyCodesComponent,
        KeyCodesFormComponent,
        downloadQrCodesZipComponent,
        QrCodeComponent,
        SystemmessageComponent
    ],
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      routes,
      HttpClientModule,
      AngularMultiSelectModule,
      FormsModule,
      ReactiveFormsModule,
      AgGridModule,
      JwtModule.forRoot({
          config: {
              allowedDomains: environment.whitelistedDomains,
              disallowedRoutes: environment.blacklistedRoutes,
              tokenGetter: tokenGetter
          }
      }),
      LoadingBarHttpClientModule,
      LoadingBarRouterModule,
      LoadingBarModule,
      MainPipe,
      UiSwitchModule,
      DraggableModule,
      NgxMaskModule.forRoot(),
      ColorPickerModule,
      NgxEchartsModule.forRoot(echartsConf),
      TooltipModule
    ],
    providers: [NavbarService, AdmingridService, AuthService, AuthGuardService,TimelineService,CanDeactivateGuard, {
        provide: HTTP_INTERCEPTORS,
        useClass: MyHttpInterceptor,
        multi: true
      },
      {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      },
    ],
        entryComponents: [ FormsinglefileComponent, downloadQrCodesZipComponent ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
