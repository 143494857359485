export function isObject(data:any){
  return data === null ? false:typeof(data) === "object";
}

export function isArray(data:any){
  return data === null ?false:Array.isArray(data);
}

export function isString(data:any){
  return  data === null ?false:typeof(data) === "string";
}
